import eas from 'erpcore/assets/svg/eas.svg';
import bugReport from 'erpcore/assets/svg/bugReport.svg';
import approve from 'erpcore/assets/svg/approve.svg';
import addBookDart from 'erpcore/assets/svg/addBookDart.svg';
import addBookDartCircle from 'erpcore/assets/svg/addBookDartCircle.svg';
import bookDart from 'erpcore/assets/svg/bookDart.svg';
import bulb from 'erpcore/assets/svg/bulb.svg';
import calculatedAmount from 'erpcore/assets/svg/calculatedAmount.svg';
import cancel from 'erpcore/assets/svg/cancel.svg';
import chat from 'erpcore/assets/svg/chat.svg';
import easPowered from 'erpcore/assets/svg/easPowered.svg';
import crop from 'erpcore/assets/svg/crop.svg';
import completion from 'erpcore/assets/svg/completion.svg';
import columns from 'erpcore/assets/svg/columns.svg';
import copy from 'erpcore/assets/svg/copy.svg';
import clientDashboard from 'erpcore/assets/svg/clientDashboard.svg';
import dashboard from 'erpcore/assets/svg/dashboard.svg';
import date from 'erpcore/assets/svg/date.svg';
import dollar from 'erpcore/assets/svg/dollar.svg';
import drag from 'erpcore/assets/svg/drag.svg';
import daysLeft from 'erpcore/assets/svg/daysLeft.svg';
import download from 'erpcore/assets/svg/download.svg';
import globe from 'erpcore/assets/svg/globe.svg';
import hoursBillable from 'erpcore/assets/svg/hoursBillable.svg';
import hoursLogged from 'erpcore/assets/svg/hoursLogged.svg';
import reject from 'erpcore/assets/svg/reject.svg';
import remove from 'erpcore/assets/svg/remove.svg';
import edit from 'erpcore/assets/svg/edit.svg';
import editSquare from 'erpcore/assets/svg/editSquare.svg';
import favorites from 'erpcore/assets/svg/favorites.svg';
import favoritesFull from 'erpcore/assets/svg/favoritesFull.svg';
import filter from 'erpcore/assets/svg/filter.svg';
import flipHorizontal from 'erpcore/assets/svg/flipHorizontal.svg';
import flipVertical from 'erpcore/assets/svg/flipVertical.svg';
import group from 'erpcore/assets/svg/group.svg';
import help from 'erpcore/assets/svg/help.svg';
import link from 'erpcore/assets/svg/link.svg';
import linkExternal from 'erpcore/assets/svg/linkExternal.svg';
import location from 'erpcore/assets/svg/location.svg';
import lock from 'erpcore/assets/svg/lock.svg';
import locked from 'erpcore/assets/svg/locked.svg';
import mail from 'erpcore/assets/svg/mail.svg';
import menu from 'erpcore/assets/svg/menu.svg';
import minus from 'erpcore/assets/svg/minus.svg';
import minusCircle from 'erpcore/assets/svg/minusCircle.svg';
import minusRound from 'erpcore/assets/svg/minusRound.svg';
import minusScale from 'erpcore/assets/svg/minusScale.svg';
import move from 'erpcore/assets/svg/move.svg';
import money from 'erpcore/assets/svg/money.svg';
import notificationError from 'erpcore/assets/svg/notificationError.svg';
import notificationSuccess from 'erpcore/assets/svg/notificationSuccess.svg';
import notificationWarning from 'erpcore/assets/svg/notificationWarning.svg';
import outputValue from 'erpcore/assets/svg/outputValue.svg';
import passHide from 'erpcore/assets/svg/passHide.svg';
import passShow from 'erpcore/assets/svg/passShow.svg';
import percent from 'erpcore/assets/svg/percent.svg';
import props from 'erpcore/assets/svg/props.svg';
import pause from 'erpcore/assets/svg/pause.svg';
import play from 'erpcore/assets/svg/play.svg';
import plus from 'erpcore/assets/svg/plus.svg';
import plusRound from 'erpcore/assets/svg/plusRound.svg';
import plusRoundThin from 'erpcore/assets/svg/plusRoundThin.svg';
import plusScale from 'erpcore/assets/svg/plusScale.svg';
import profile from 'erpcore/assets/svg/profile.svg';
import proTip1 from 'erpcore/assets/svg/proTip1.svg';
import pieChart from 'erpcore/assets/svg/pieChart.svg';
import rotateLeft from 'erpcore/assets/svg/rotateLeft.svg';
import rotateRight from 'erpcore/assets/svg/rotateRight.svg';
import signOut from 'erpcore/assets/svg/signOut.svg';
import search from 'erpcore/assets/svg/search.svg';
import settings from 'erpcore/assets/svg/settings.svg';
import sorting from 'erpcore/assets/svg/sorting.svg';
import statistics from 'erpcore/assets/svg/statistics.svg';
import stopwatch from 'erpcore/assets/svg/stopwatch.svg';
import tag from 'erpcore/assets/svg/tag.svg';
import tie from 'erpcore/assets/svg/tie.svg';
import time from 'erpcore/assets/svg/time.svg';
import timeRemaining from 'erpcore/assets/svg/timeRemaining.svg';
import upload from 'erpcore/assets/svg/upload.svg';
import userMirrorIcon from 'erpcore/assets/svg/userMirrorIcon.svg';
import userMirrorIconSign from 'erpcore/assets/svg/userMirrorIconSign.svg';
import elementLoader from 'erpcore/assets/svg/elementLoader.svg';
import close from 'erpcore/assets/svg/close.svg';
import arrowDown from 'erpcore/assets/svg/arrowDown.svg';
import arrowUp from 'erpcore/assets/svg/arrowUp.svg';
import arrowLeft from 'erpcore/assets/svg/arrowLeft.svg';
import arrowRight from 'erpcore/assets/svg/arrowRight.svg';
import checkmark from 'erpcore/assets/svg/checkmark.svg';
import action from 'erpcore/assets/svg/action.svg';
import notFound from 'erpcore/assets/svg/notFound.svg';
import file from 'erpcore/assets/svg/file.svg';
import arrowDownRight from 'erpcore/assets/svg/arrowDownRight.svg';
import x from 'erpcore/assets/svg/x.svg';
import harvest from 'erpcore/assets/svg/harvest.svg';
import toggl from 'erpcore/assets/svg/toggl.svg';
import jira from 'erpcore/assets/svg/jira.svg';
import slack from 'erpcore/assets/svg/slack.svg';
import info from 'erpcore/assets/svg/info.svg';
import expenses from 'erpcore/assets/svg/expenses.svg';
import groupLabourCost from 'erpcore/assets/svg/groupLabourCost.svg';
import share from 'erpcore/assets/svg/share.svg';
import forbidden from 'erpcore/assets/svg/forbidden.svg';
import freshbooks from 'erpcore/assets/svg/freshbooks.svg';
import filledCheckMark from 'erpcore/assets/svg/filledCheckMark.svg';
import clock from 'erpcore/assets/svg/clock.svg';
import ganttChartIcon from 'erpcore/assets/svg/ganttChartIcon.svg';
import listingIcon from 'erpcore/assets/svg/listingIcon.svg';
import quickbooks from 'erpcore/assets/svg/quickbooks.svg';
import addTimeline from 'erpcore/assets/svg/addTimeline.svg';
import arrowsLeftRight from 'erpcore/assets/svg/iconsArrowRightLeft.svg';
import sage from 'erpcore/assets/svg/sage.svg';
import sortASC from 'erpcore/assets/svg/sortASC.svg';
import sortDESC from 'erpcore/assets/svg/sortDESC.svg';
import warning from 'erpcore/assets/svg/warning.svg';
import ASLogo from 'erpcore/assets/svg/ASLogo.svg';
import ASLogoWhite from 'erpcore/assets/svg/ASLogoWhite.svg';
import ASLogoIcon from 'erpcore/assets/svg/ASLogoIcon.svg';
import ASLogoIconWhite from 'erpcore/assets/svg/ASLogoIconWhite.svg';
import xero from 'erpcore/assets/svg/xero.svg';
import plusThick from 'erpcore/assets/svg/plusThick.svg';
import minusThick from 'erpcore/assets/svg/minusThick.svg';

export default {
    approve,
    addBookDart,
    addBookDartCircle,
    bookDart,
    bugReport,
    bulb,
    eas,
    easPowered,
    calculatedAmount,
    cancel,
    chat,
    completion,
    columns,
    copy,
    crop,
    clientDashboard,
    date,
    dollar,
    drag,
    dashboard,
    daysLeft,
    download,
    globe,
    hoursBillable,
    hoursLogged,
    reject,
    remove,
    edit,
    editSquare,
    favorites,
    favoritesFull,
    filter,
    flipHorizontal,
    flipVertical,
    group,
    help,
    link,
    linkExternal,
    location,
    lock,
    locked,
    mail,
    menu,
    minus,
    minusCircle,
    minusRound,
    minusScale,
    money,
    move,
    notificationError,
    notificationSuccess,
    notificationWarning,
    outputValue,
    percent,
    profile,
    props,
    passHide,
    passShow,
    pause,
    play,
    plus,
    plusRound,
    plusRoundThin,
    plusScale,
    proTip1,
    pieChart,
    rotateLeft,
    rotateRight,
    signOut,
    search,
    settings,
    sorting,
    statistics,
    stopwatch,
    tag,
    tie,
    time,
    timeRemaining,
    upload,
    userMirrorIcon,
    userMirrorIconSign,
    elementLoader,
    close,
    arrowDown,
    arrowUp,
    arrowLeft,
    arrowRight,
    checkmark,
    action,
    notFound,
    file,
    arrowDownRight,
    x,
    harvest,
    toggl,
    jira,
    slack,
    info,
    expenses,
    groupLabourCost,
    share,
    forbidden,
    freshbooks,
    filledCheckMark,
    clock,
    quickbooks,
    listingIcon,
    ganttChartIcon,
    addTimeline,
    arrowsLeftRight,
    sage,
    sortASC,
    sortDESC,
    warning,
    ASLogo,
    ASLogoIcon,
    ASLogoWhite,
    ASLogoIconWhite,
    xero,
    plusThick,
    minusThick
};
